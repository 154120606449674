declare var window: any;
import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { UnsubscribeOnDestroyAdapter } from "@iris/iris-base";

@Component({
  selector: 'app-iris-bancadigital',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends UnsubscribeOnDestroyAdapter implements OnInit {
  routerSubject: Subject<string> = new Subject<string>();

  constructor(private router: Router) {
    super();
    window.routerSubject = this.routerSubject;
  }

  ngOnInit() {
    this.subs.add(this.routerSubject.subscribe((url: string) => {
      this.router.navigate([`/${url}`]);
    }));
  }
}
