export const environment = {
  production: false,
  idleTimeout: 5,
  eprepaidApi: 'https://api.dev.irsbnk.co/iriscard',
  transactionsApi:
    'https://api.dev.irsbnk.co/empresas-financial-2/transactions',
  notificationsApi: 'https://api.dev.irsbnk.co/empresas-2/notifications',
  depositAccountsApi:
    'https://api.dev.irsbnk.co/empresas-financial-2/depositaccounts',
  dailyBalancesApi:
    'https://api.dev.irsbnk.co/empresas-financial-2/dailybalances',
  accountApi: 'https://api.dev.irsbnk.co/empresas-2/account',
  userApi: 'https://api.dev.irsbnk.co/empresas-2/users',
  approvalEprepaidApi: 'https://api.dev.irsbnk.co/eprepaid/approval',
  groupApi: 'https://api.dev.irsbnk.co/empresas-financial-2/groups',
  roleApi: 'https://api.dev.irsbnk.co/empresas-2/roles',
  otpApi: 'https://api.dev.irsbnk.co/empresas-2/otp',
  banksApi: 'https://api.dev.irsbnk.co/empresas-financial-2/banks',
  tagApi: 'https://api.dev.irsbnk.co/empresas-2/tags',
  registerAccountsApi:
    'https://api.dev.irsbnk.co/empresas-financial-2/inscribedaccounts',
  batchApi: 'https://api.dev.irsbnk.co/empresas-financial-2/batches',
  logsApi: 'https://api.dev.irsbnk.co/empresas-2/auditlogs',
  authorizationsApi:
    'https://api.dev.irsbnk.co/empresas-financial-2/authorizations',
  exportsApi: 'https://api.dev.irsbnk.co/empresas-financial-2/exports',
  holidaysApi: 'https://api.dev.irsbnk.co/empresas-2/holidays',
  loginApi: 'https://api.dev.irsbnk.co/empresas-2/login',
  loansApi: 'https://api.dev.irsbnk.co/empresas-financial-2/loans',
  plansApi: 'https://api.dev.irsbnk.co/empresas-financial-2/plans',
  categoriesApi: 'https://api.dev.irsbnk.co/empresas-2/categories',
  billingApi: 'https://api.dev.irsbnk.co/irispay/billing',
  websocketApi: 'wss://apiv2.dev.irsbnk.co/empresas-websocket-2',
  staticApiKey: 'V7hMyd5AU059Td27lUvIG1XoM5WiY2DI2DMFMer3',
  sessionConfiguration: {
    domain: 'empresas-2.dev.iristesting.com.co',
    path: '/',
    expires: 365,
    secure: true,
    sameSite: 'strict',
  },
  addOnsApi: 'https://api.dev.irsbnk.co/empresas-complements/complements',
  depositsApi: 'https://api.dev.irsbnk.co/empresas-financial-2/deposits',
};
